$ASSISTANT_TYPE: med;
@import 'src/styles/colors';

.sign-in-btn {
  align-items: center;
  color: $secondary-color;
  display: flex;
  font-size: 12px;
  margin-left: 16px;

  img {
    height: '30px';
    margin-right: 10px;
    width: '30px';
  }
}
