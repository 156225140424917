$ASSISTANT_TYPE: med;
@import 'src/styles/colors';

.app-footer {
  background-color: $secondary-color;
  color: #fff;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    margin: 0;
    padding: 0;

    a {
      color: inherit;
    }
  }
}
