$ASSISTANT_TYPE: med;
@import 'src/styles/colors';

.decision-matrix {
  &__intro {
    margin: 0;
    padding: 59px 0 49px;
    text-align: center;

    span {
      color: $secondary-color;
    }
  }

  &__names {
    .MuiButton-root {
      border-radius: 56px;
      font-size: 1.125rem;
      min-width: 290px;
    }
    justify-content: space-evenly;
    margin-bottom: 36px;
  }

  &__content {
    background-color: #eee;
    border-radius: 20px;
    margin-bottom: 24px;
    min-height: 400px;
    padding: 20px 34px;

    .MuiDivider-root {
      background-color: $secondary-color;
    }
  }

  &__leaves,
  &__nodes {
    .MuiButton-root {
      border-radius: 8px;
      font-size: 1rem;
    }
  }

  &__nodes:first-child {
    margin-top: 0;
  }

  &__nodes {
    justify-content: center;
    margin-bottom: 16px;
    margin-top: 16px;
  }

  &__leaves {
    margin-bottom: 16px;
    margin-top: 24px;
  }

  &__centered {
    justify-content: center;
  }
}
