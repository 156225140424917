$ASSISTANT_TYPE: med;
@import 'src/styles/colors';

.profile-link {
  align-items: center;
  color: $secondary-color;
  cursor: pointer;
  display: flex;

  &:hover {
    text-decoration: none;
  }

  img {
    margin-right: 10px;
  }
}
